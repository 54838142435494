export const APP_NAME = "BOAhub";
export const DEFAULT_TAX = 15;
export const AU_TAX = 10;
// export const SITE_KEY = "6LdXj2AUAAAAAEYJhxzChBD9UcB9-hoSpZkNUztA";
export const SITE_KEY = "6LczTSghAAAAANn0uoFfhHhuraROUAWhcyhErMfO";
// #deployment:-need to change footer path before deploying
export const FOOTER_PAGES_PATH = "https://www.boa.co.nz/";

const siteBase =
  "https://s3-ap-southeast-2.amazonaws.com/portal-static-images/categoryImages/";
const base_url = siteBase;
// + "/boa/wp-content/uploads/2018/08/"
export const customerRoles = [
  { key: "none", value: "", text: "Select customer role" },
  { key: "accounting", value: "Accounting", text: "Accounting" },
  { key: "crew_manager", value: "Crew Manager", text: "Crew Manager" },
  { key: "crew_member", value: "Crew Member", text: "Crew Member" },
  { key: "general_manager", value: "General Manager", text: "General Manager" },
  { key: "hose_champion", value: "Hose Champion", text: "Hose Champion" },
  { key: "mechanic", value: "Mechanic", text: "Mechanic" },
  { key: "operator", value: "Operator", text: "Operator" },
  {
    key: "operations_manager",
    value: "Operations Manager",
    text: "Operations Manager",
  },
  { key: "owner", value: "Owner", text: "Owner" },
  { key: "site_manager", value: "Site Manager", text: "Site Manager" },
  { key: "team_leader", value: "Team Leader", text: "Team Leader" },
];

export const industries2 = [
  { key: "none", value: "", text: "Select industry role" },
  {
    key: "Agriculture",
    value: "Agriculture",
    text: "Agriculture",
  },
  { key: "Civil", value: "Civil", text: "Civil" },
  { key: "Engineering", value: "Engineering", text: "Engineering" },
  { key: "Forestry", value: "Forestry", text: "Forestry" },
  { key: "Machinery", value: "Machinery", text: "Machinery" },
  // { key: "Hire", value: "Hire", text: "Hire" },
  // { key: "Mining", value: "Mining", text: "Mining" },
  // { key: "Transport", value: "Transport", text: "Transport" },
  { key: "Manufacturing", value: "Manufacturing", text: "Manufacturing" },
  { key: "Mechanical", value: "Mechanical", text: "Mechanical" },
  { key: "Marine", value: "Marine", text: "Marine" },
  { key: "OEM", value: "OEM", text: "OEM" },
  { key: "Other", value: "Other", text: "Other" },
];

export const industries = [
  { key: "none", value: "", text: "Select inddustry role" },
  { key: "1", value: "1", text: "Agriculture" },
  { key: "2", value: "2", text: "Civil Construction / Earthmoving" },
  { key: "3", value: "3", text: "General Engineering" },
  { key: "4", value: "4", text: "Forestry" },
  { key: "5", value: "5", text: "Heavy Mechanic/Fitter" },
  { key: "6", value: "6", text: "Equipment Manufacture" },
  { key: "7", value: "7", text: "Mining" },
  { key: "8", value: "8", text: "Quarrying" },
  { key: "9", value: "9", text: "Demolition" },
  { key: "10", value: "10", text: "Other" },
];

export const states = [
  { key: "none", value: "", text: "Select state role" },

  { key: 1, value: "1", text: "Australian Capital Territory" },
  { key: 2, value: "2", text: "New South Wales" },
  { key: 3, value: "3", text: "Northern Territory" },
  { key: 4, value: "4", text: "Queensland" },
  { key: 5, value: "5", text: "South Australia" },
  { key: 6, value: "6", text: "Tasmania" },
  { key: 7, value: "7", text: "Victoria" },
  { key: 8, value: "8", text: "Western Australia" },
  { key: 9, value: "9", text: "Alabama" },
  { key: 6, value: "6", text: "Alaska" },
  { key: 16, value: "16", text: "Arizona" },
  { key: 12, value: "12", text: "Arkansas" },
  { key: 18, value: "18", text: "California" },
  { key: 20, value: "20", text: "Colorado" },
  { key: 22, value: "22", text: "Connecticut" },
  { key: 26, value: "26", text: "Delaware" },
  { key: 28, value: "28", text: "Florida" },
  { key: 32, value: "32", text: "Georgia" },
  { key: 36, value: "36", text: "Hawaii" },
  { key: 40, value: "40", text: "Idaho" },
  { key: 42, value: "42", text: "Illinois" },
  { key: 44, value: "44", text: "Indiana" },
  { key: 38, value: "38", text: "Iowa" },
  { key: 46, value: "46", text: "Kansas" },
  { key: 48, value: "48", text: "Kentucky" },
  { key: 50, value: "50", text: "Louisiana" },
  { key: 56, value: "56", text: "Maine" },
  { key: 54, value: "54", text: "Maryland" },
  { key: 52, value: "52", text: "Massachusetts" },
  { key: 60, value: "60", text: "Michigan" },
  { key: 62, value: "62", text: "Minnesota" },
  { key: 68, value: "68", text: "Mississippi" },
  { key: 64, value: "64", text: "Missouri" },
  { key: 70, value: "70", text: "Montana" },
  { key: 76, value: "76", text: "Nebraska" },
  { key: 84, value: "84", text: "Nevada" },
  { key: 78, value: "78", text: "New Hampshire" },
  { key: 80, value: "80", text: "New Jersey" },
  { key: 82, value: "82", text: "New Mexico" },
  { key: 86, value: "86", text: "New York" },
  { key: 72, value: "72", text: "North Carolina" },
  { key: 74, value: "74", text: "North Dakota" },
  { key: 88, value: "88", text: "Ohio" },
  { key: 90, value: "90", text: "Oklahoma" },
  { key: 92, value: "92", text: "Oregon" },
  { key: 94, value: "94", text: "Pennsylvania" },
  { key: 100, value: "100", text: "Rhode Island" },
  { key: 102, value: "102", text: "South Carolina" },
  { key: 104, value: "104", text: "South Dakota" },
  { key: 106, value: "106", text: "Tennessee" },
  { key: 108, value: "108", text: "Texas" },
  { key: 110, value: "110", text: "Utah" },
  { key: 116, value: "116", text: "Vermont" },
  { key: 112, value: "112", text: "Virginia" },
  { key: 118, value: "118", text: "Washington" },
  { key: 122, value: "122", text: "West Virginia" },
  { key: 120, value: "120", text: "Wisconsin" },
  { key: 124, value: "124", text: "Wyoming" },
  { key: 517, value: "517", text: "Auckland" },
  { key: 518, value: "518", text: "Bay of Plenty" },
  { key: 519, value: "519", text: "Canterbury" },
  { key: 520, value: "520", text: "Gisborne" },
  { key: 521, value: "521", text: "Hawke's Bay" },
  { key: 522, value: "522", text: "Manawatu-Wanganui" },
  { key: 523, value: "523", text: "Marlborough" },
  { key: 524, value: "524", text: "Nelson" },
  { key: 525, value: "525", text: "Northland" },
  { key: 526, value: "526", text: "Otago" },
  { key: 527, value: "527", text: "Southland" },
  { key: 528, value: "528", text: "Taranaki" },
  { key: 529, value: "529", text: "Tasman" },
  { key: 530, value: "530", text: "Waikato" },
  { key: 531, value: "531", text: "Wellington" },
  { key: 532, value: "532", text: "West Coast" },
  { key: 1404, value: "1404", text: "Marlbough" },
  { key: 1437, value: "1437", text: "Timaru" },
  { key: 1439, value: "1439", text: "Kaiapoi (NZ)" },
  { key: 1441, value: "1441", text: "Kaiapoi" },
  { key: 1443, value: "1443", text: "Kaitaia" },
  { key: 1451, value: "1451", text: "Wairarapa" },
  { key: 1452, value: "1452", text: "Rotorua/Taupo" },
];

export const countries = [
  { key: "none", value: "", text: "Select country role" },
  { key: 13, value: "13", text: "Australia" },
  { key: 170, value: "170", text: "New Zealand" },
  { key: 233, value: "233", text: "United States" },
  { key: 0, value: "0", text: "Other" },
];

export const restrictedUserRoles = ["Operators", "Operator", "Crew Member"];
export const FITTINGS_HYD = "Swage Hose Tails";
export const ADAPTORS_HYD = "Adaptors";

export const brands = [
  { name: "BOAforce", path: "boa-force-fittings" },
  { name: "OP", path: "op" },
  { name: "Yokohama", path: "yokohama" },
  { name: "DNP", path: "dnp" },
  { name: "SILFLO", path: "silflo-hose" },
  { name: "Uniflex", path: "uniflex" },
];

export const importantLink = [
  { name: "Contact us", path: "contact-us" },
  { name: "Terms & Conditions", path: "terms-conditions" },
  { name: "Privacy policy", path: "privacy-policy" },
];

export const eComFeatures = [
  {
    page: "jobbing",
    title: "Job Management",
    image: "../../assets/images/pages/jobbing.png",
    active: true,
  },
  {
    page: "find-boapod",
    title: "Find BOApod",
    image: "setting",
    active: true,
  },
  {
    page: "my-assets",
    title: "My Assets",
    image: "setting",
    active: true,
  },
  {
    page: "my-customers",
    title: "My Customers",
    image: "myCustomers",
    active: true,
  },
  {
    page: "search",
    title: "Products",
    icon: "search",
    active: true,
  },
  {
    page: "order-history",
    title: "My Orders",
    icon: "history",
    active: true,
  },
  {
    page: "my-resources",
    title: "My Resources",
    icon: "file",
    active: true,
  },
  {
    page: "boa-his",
    title: "Hydraulic Information System",
    icon: "file",
    active: true,
  },
  {
    page: "inventory-management",
    title: "Inventory Management",
    icon: "file",
    active: true,
  },
  {
    page: "settings",
    title: "Settings",
    image: "setting",
    active: true,
  },
];

export const facilities = [
  {
    label: "My Inventory",
    link: "inventory",
    icon: "industry",
  },
  {
    label: "My Assets",
    link: "asset",
    icon: "zip",
  },
  {
    label: "My Schedule",
    link: "schedule",
    icon: "calendar check",
  },
  {
    label: "Hose Mechanic",
    link: "hoseMechanic",
    icon: "cogs",
  },
  {
    label: "Inspection Forms",
    link: "maintenanceForm",
    icon: "wpforms",
  },

  {
    label: "Crews",
    link: "crew",
    icon: "users",
  },
];

export const CATEGORIES_IMAGES = {
  Accessories: base_url + "Accessories-hyd.jpg",
  Adaptors: base_url + "Adaptors.jpg",
  "Swage Hose Tails": base_url + "Hydraulic_Fittings_1.jpg",
  Hose: base_url + "Hydraulic_Hose_1.jpg",
  "Quick Release Couplers": base_url + "QRC_1.jpg",
  "Workshop Machinery": base_url + "Machines_2.jpg",
  "Hydraulic Ferrules": base_url + "Ferrules_1.jpg",
};

export const hoseAdditionalProductsCategories = [
  "Adaptors",
  "Accessories",
  "Quick Release Couplers",
];

export const weeklyList = [
  { name: "Monday", value: 1 },
  { name: "Tuesday", value: 2 },
  { name: "Wednesday", value: 3 },
  { name: "Thursday", value: 4 },
  { name: "Friday", value: 5 },
  { name: "Saturday", value: 6 },
  { name: "Sunday", value: 0 },
];
export const TERMS_CONDITION_LINK = "https://boa.co.nz/terms-and-conditions/";
export const TBA = "TBA - Please call 0800 20 20 20";

export const FITTING_TERTIARY_GROUP_DISPLAY_NAME_FOR_FERRULE_CONDITION =
  "S-Series 2-Piece Swage Fittings";
export const HOSE_FITTING_IMAGE_S3_LINK =
  "https://s3-ap-southeast-2.amazonaws.com/portal-static-images/hoseMaker/";

export const stock_operation = [
  { value: "ALL", text: "ALL" },
  { value: "ADDED_MANUALLY", text: "ADDED MANUALLY" },
  { value: "ADDED_ORDER_HISTORY", text: "ADDED ORDER HISTORY" },
  { value: "ATTACHED", text: "ATTACHED" },
  { value: "CONSUMED", text: "CONSUMED" },
  { value: "REVIEWED", text: "REVIEWED" },
  { value: "SOLD", text: "SOLD" },
  { value: "TRANSFER_LOCATION", text: "TRANSFER LOCATION" },
  { value: "HOSE_ASSEMBLY", text: "HOSE ASSEMBLY" },
  { value: "REMOVED", text: "REMOVED" },
  { value: "ATTACHED_HOSE_ASSEMBLY", text: "ATTACHED HOSE ASSEMBLY" },
];
export const PRICE_ZERO_TEXT = "Call BOA on 0800 20 20 20 for this item";

export const ASSET_ID_FOR_URL_PARAM = "assetId";
export const ASSET_NAME_FOR_URL_PARAM = "assetName";
export const ASSET_LOCATION_FOR_URL_PARAM = "position";
export const HOSE_ID_FOR_URL_PARAM = "hoseId";
export const ASSET_MODEL_FOR_URL_PARAM = "model";
export const ASSET_SUB_MODEL_FOR_URL_PARAM = "subModel";
export const ASSET_POSITION_FOR_URL_PARAM = "position";
export const ASSET_POSITION_ID_FOR_URL_PARAM = "positionId";
export const ASSET_MAKE_FOR_URL_PARAM = "make";

export const SEAT_TYPE_S3_IMAGE_URL =
  "https://s3-ap-southeast-2.amazonaws.com/portal-static-images/threadTypeFilters/";
export const FULL_BOX_POLICY = "ezisXob"; // boX size - default policy name

// All countries list

export const allcCountryList = [
  {
    id: 1,
    code: "AD",
    name: "Andorra",
  },
  {
    id: 2,
    code: "AE",
    name: "United Arab Emirates",
  },
  {
    id: 3,
    code: "AF",
    name: "Afghanistan",
  },
  {
    id: 4,
    code: "AG",
    name: "Antigua and Barbuda",
  },
  {
    id: 5,
    code: "AI",
    name: "Anguilla",
  },
  {
    id: 6,
    code: "AL",
    name: "Albania",
  },
  {
    id: 7,
    code: "AM",
    name: "Armenia",
  },
  {
    id: 8,
    code: "AO",
    name: "Angola",
  },
  {
    id: 9,
    code: "AQ",
    name: "Antarctica",
  },
  {
    id: 10,
    code: "AR",
    name: "Argentina",
  },
  {
    id: 11,
    code: "AS",
    name: "American Samoa",
  },
  {
    id: 12,
    code: "AT",
    name: "Austria",
  },
  {
    id: 14,
    code: "AW",
    name: "Aruba",
  },
  {
    id: 15,
    code: "AX",
    name: "Åland Islands",
  },
  {
    id: 16,
    code: "AZ",
    name: "Azerbaijan",
  },
  {
    id: 17,
    code: "BA",
    name: "Bosnia and Herzegovina",
  },
  {
    id: 19,
    code: "BD",
    name: "Bangladesh",
  },
  {
    id: 20,
    code: "BE",
    name: "Belgium",
  },
  {
    id: 21,
    code: "BF",
    name: "Burkina Faso",
  },
  {
    id: 22,
    code: "BG",
    name: "Bulgaria",
  },
  {
    id: 23,
    code: "BH",
    name: "Bahrain",
  },
  {
    id: 24,
    code: "BI",
    name: "Burundi",
  },
  {
    id: 25,
    code: "BJ",
    name: "Benin",
  },
  {
    id: 26,
    code: "BL",
    name: "Saint Barthélémy",
  },
  {
    id: 27,
    code: "BM",
    name: "Bermuda",
  },
  {
    id: 28,
    code: "BN",
    name: "Brunei Darussalam",
  },
  {
    id: 29,
    code: "BO",
    name: "Bolivia",
  },
  {
    id: 30,
    code: "BQ",
    name: "Bonaire, Sint Eustatius and Saba",
  },
  {
    id: 31,
    code: "BR",
    name: "Brazil",
  },
  {
    id: 32,
    code: "BS",
    name: "Bahamas",
  },
  {
    id: 33,
    code: "BT",
    name: "Bhutan",
  },
  {
    id: 34,
    code: "BV",
    name: "Bouvet Island",
  },
  {
    id: 35,
    code: "BW",
    name: "Botswana",
  },
  {
    id: 36,
    code: "BY",
    name: "Belarus",
  },
  {
    id: 37,
    code: "BZ",
    name: "Belize",
  },
  {
    id: 38,
    code: "CA",
    name: "Canada",
  },
  {
    id: 39,
    code: "CC",
    name: "Cocos (Keeling) Islands",
  },
  {
    id: 40,
    code: "CF",
    name: "Central African Republic",
  },
  {
    id: 41,
    code: "CD",
    name: "Democratic Republic of the Congo",
  },
  {
    id: 42,
    code: "CG",
    name: "Congo",
  },
  {
    id: 43,
    code: "CH",
    name: "Switzerland",
  },
  {
    id: 44,
    code: "CI",
    name: "Côte d'Ivoire",
  },
  {
    id: 45,
    code: "CK",
    name: "Cook Islands",
  },
  {
    id: 46,
    code: "CL",
    name: "Chile",
  },
  {
    id: 47,
    code: "CM",
    name: "Cameroon",
  },
  {
    id: 48,
    code: "CN",
    name: "China",
  },
  {
    id: 49,
    code: "CO",
    name: "Colombia",
  },
  {
    id: 50,
    code: "CR",
    name: "Costa Rica",
  },
  {
    id: 51,
    code: "CU",
    name: "Cuba",
  },
  {
    id: 52,
    code: "CV",
    name: "Cape Verde",
  },
  {
    id: 53,
    code: "CW",
    name: "Curaçao",
  },
  {
    id: 54,
    code: "CX",
    name: "Christmas Island",
  },
  {
    id: 55,
    code: "CY",
    name: "Cyprus",
  },
  {
    id: 56,
    code: "CZ",
    name: "Czech Republic",
  },
  {
    id: 57,
    code: "DE",
    name: "Germany",
  },
  {
    id: 58,
    code: "DJ",
    name: "Djibouti",
  },
  {
    id: 59,
    code: "DK",
    name: "Denmark",
  },
  {
    id: 60,
    code: "DM",
    name: "Dominica",
  },
  {
    id: 61,
    code: "DO",
    name: "Dominican Republic",
  },
  {
    id: 62,
    code: "DZ",
    name: "Algeria",
  },
  {
    id: 63,
    code: "EC",
    name: "Ecuador",
  },
  {
    id: 64,
    code: "EE",
    name: "Estonia",
  },
  {
    id: 65,
    code: "EG",
    name: "Egypt",
  },
  {
    id: 66,
    code: "EH",
    name: "Western Sahara",
  },
  {
    id: 67,
    code: "ER",
    name: "Eritrea",
  },
  {
    id: 68,
    code: "ES",
    name: "Spain",
  },
  {
    id: 69,
    code: "ET",
    name: "Ethiopia",
  },
  {
    id: 70,
    code: "FI",
    name: "Finland",
  },
  {
    id: 71,
    code: "FJ",
    name: "Fiji",
  },
  {
    id: 72,
    code: "FK",
    name: "Falkland Islands",
  },
  {
    id: 73,
    code: "FM",
    name: "Micronesia",
  },
  {
    id: 74,
    code: "FO",
    name: "Faroe Islands",
  },
  {
    id: 75,
    code: "FR",
    name: "France",
  },
  {
    id: 76,
    code: "GA",
    name: "Gabon",
  },
  {
    id: 77,
    code: "GD",
    name: "Grenada",
  },
  {
    id: 78,
    code: "GE",
    name: "Georgia",
  },
  {
    id: 79,
    code: "GF",
    name: "French Guiana",
  },
  {
    id: 80,
    code: "GH",
    name: "Ghana",
  },
  {
    id: 81,
    code: "GI",
    name: "Gibraltar",
  },
  {
    id: 82,
    code: "GG",
    name: "Guernsey",
  },
  {
    id: 83,
    code: "GL",
    name: "Greenland",
  },
  {
    id: 84,
    code: "GM",
    name: "Gambia",
  },
  {
    id: 85,
    code: "GN",
    name: "Guinea",
  },
  {
    id: 86,
    code: "GP",
    name: "Guadeloupe",
  },
  {
    id: 87,
    code: "GQ",
    name: "Equatorial Guinea",
  },
  {
    id: 88,
    code: "GR",
    name: "Greece",
  },
  {
    id: 89,
    code: "GS",
    name: "South Georgia and the South Sandwich Islands",
  },
  {
    id: 90,
    code: "GT",
    name: "Guatemala",
  },
  {
    id: 91,
    code: "GU",
    name: "Guam",
  },
  {
    id: 92,
    code: "GW",
    name: "Guinea-Bissau",
  },
  {
    id: 93,
    code: "GY",
    name: "Guyana",
  },
  {
    id: 94,
    code: "HK",
    name: "Hong Kong",
  },
  {
    id: 95,
    code: "HM",
    name: "Heard Island and McDonald Islands",
  },
  {
    id: 96,
    code: "HN",
    name: "Honduras",
  },
  {
    id: 97,
    code: "HR",
    name: "Croatia",
  },
  {
    id: 98,
    code: "HT",
    name: "Haiti",
  },
  {
    id: 99,
    code: "HU",
    name: "Hungary",
  },
  {
    id: 100,
    code: "ID",
    name: "Indonesia",
  },
  {
    id: 101,
    code: "IE",
    name: "Ireland",
  },
  {
    id: 102,
    code: "IL",
    name: "Israel",
  },
  {
    id: 103,
    code: "IM",
    name: "Isle of Man",
  },
  {
    id: 104,
    code: "IN",
    name: "India",
  },
  {
    id: 105,
    code: "IO",
    name: "British Indian Ocean Territory",
  },
  {
    id: 106,
    code: "IQ",
    name: "Iraq",
  },
  {
    id: 107,
    code: "IR",
    name: "Iran",
  },
  {
    id: 108,
    code: "IS",
    name: "Iceland",
  },
  {
    id: 109,
    code: "IT",
    name: "Italy",
  },
  {
    id: 110,
    code: "JE",
    name: "Jersey",
  },
  {
    id: 111,
    code: "JM",
    name: "Jamaica",
  },
  {
    id: 112,
    code: "JO",
    name: "Jordan",
  },
  {
    id: 113,
    code: "JP",
    name: "Japan",
  },
  {
    id: 114,
    code: "KE",
    name: "Kenya",
  },
  {
    id: 115,
    code: "KG",
    name: "Kyrgyzstan",
  },
  {
    id: 116,
    code: "KH",
    name: "Cambodia",
  },
  {
    id: 117,
    code: "KI",
    name: "Kiribati",
  },
  {
    id: 118,
    code: "KM",
    name: "Comoros",
  },
  {
    id: 119,
    code: "KN",
    name: "Saint Kitts and Nevis",
  },
  {
    id: 120,
    code: "KP",
    name: "North Korea",
  },
  {
    id: 121,
    code: "KR",
    name: "South Korea",
  },
  {
    id: 122,
    code: "KW",
    name: "Kuwait",
  },
  {
    id: 123,
    code: "KY",
    name: "Cayman Islands",
  },
  {
    id: 124,
    code: "KZ",
    name: "Kazakhstan",
  },
  {
    id: 125,
    code: "LA",
    name: "Laos",
  },
  {
    id: 126,
    code: "LB",
    name: "Lebanon",
  },
  {
    id: 127,
    code: "LC",
    name: "Saint Lucia",
  },
  {
    id: 128,
    code: "LI",
    name: "Liechtenstein",
  },
  {
    id: 129,
    code: "LK",
    name: "Sri Lanka",
  },
  {
    id: 130,
    code: "LR",
    name: "Liberia",
  },
  {
    id: 131,
    code: "LS",
    name: "Lesotho",
  },
  {
    id: 132,
    code: "LT",
    name: "Lithuania",
  },
  {
    id: 133,
    code: "LU",
    name: "Luxembourg",
  },
  {
    id: 134,
    code: "LV",
    name: "Latvia",
  },
  {
    id: 135,
    code: "LY",
    name: "Libya",
  },
  {
    id: 136,
    code: "MA",
    name: "Morocco",
  },
  {
    id: 137,
    code: "MC",
    name: "Monaco",
  },
  {
    id: 138,
    code: "MD",
    name: "Moldova",
  },
  {
    id: 139,
    code: "ME",
    name: "Montenegro",
  },
  {
    id: 140,
    code: "MF",
    name: "Saint Martin (French part)",
  },
  {
    id: 141,
    code: "MG",
    name: "Madagascar",
  },
  {
    id: 142,
    code: "MH",
    name: "Marshall Islands",
  },
  {
    id: 143,
    code: "MK",
    name: "North Macedonia",
  },
  {
    id: 144,
    code: "ML",
    name: "Mali",
  },
  {
    id: 145,
    code: "MM",
    name: "Myanmar",
  },
  {
    id: 146,
    code: "MN",
    name: "Mongolia",
  },
  {
    id: 147,
    code: "MO",
    name: "Macau",
  },
  {
    id: 148,
    code: "MP",
    name: "Northern Mariana Islands",
  },
  {
    id: 149,
    code: "MQ",
    name: "Martinique",
  },
  {
    id: 150,
    code: "MR",
    name: "Mauritania",
  },
  {
    id: 151,
    code: "MS",
    name: "Montserrat",
  },
  {
    id: 152,
    code: "MT",
    name: "Malta",
  },
  {
    id: 153,
    code: "MU",
    name: "Mauritius",
  },
  {
    id: 154,
    code: "MV",
    name: "Maldives",
  },
  {
    id: 155,
    code: "MW",
    name: "Malawi",
  },
  {
    id: 156,
    code: "MX",
    name: "Mexico",
  },
  {
    id: 157,
    code: "MY",
    name: "Malaysia",
  },
  {
    id: 158,
    code: "MZ",
    name: "Mozambique",
  },
  {
    id: 159,
    code: "NA",
    name: "Namibia",
  },
  {
    id: 160,
    code: "NC",
    name: "New Caledonia",
  },
  {
    id: 161,
    code: "NE",
    name: "Niger",
  },
  {
    id: 162,
    code: "NF",
    name: "Norfolk Island",
  },
  {
    id: 163,
    code: "NG",
    name: "Nigeria",
  },
  {
    id: 164,
    code: "NI",
    name: "Nicaragua",
  },
  {
    id: 165,
    code: "NL",
    name: "Netherlands",
  },
  {
    id: 166,
    code: "NO",
    name: "Norway",
  },
  {
    id: 167,
    code: "NP",
    name: "Nepal",
  },
  {
    id: 168,
    code: "NR",
    name: "Nauru",
  },
  {
    id: 169,
    code: "NU",
    name: "Niue",
  },
  {
    id: 171,
    code: "OM",
    name: "Oman",
  },
  {
    id: 172,
    code: "PA",
    name: "Panama",
  },
  {
    id: 173,
    code: "PE",
    name: "Peru",
  },
  {
    id: 174,
    code: "PF",
    name: "French Polynesia",
  },
  {
    id: 175,
    code: "PG",
    name: "Papua New Guinea",
  },
  {
    id: 176,
    code: "PH",
    name: "Philippines",
  },
  {
    id: 177,
    code: "PK",
    name: "Pakistan",
  },
  {
    id: 178,
    code: "PL",
    name: "Poland",
  },
  {
    id: 179,
    code: "PM",
    name: "Saint Pierre and Miquelon",
  },
  {
    id: 180,
    code: "PN",
    name: "Pitcairn Islands",
  },
  {
    id: 181,
    code: "PR",
    name: "Puerto Rico",
  },
  {
    id: 182,
    code: "PS",
    name: "State of Palestine",
  },
  {
    id: 183,
    code: "PT",
    name: "Portugal",
  },
  {
    id: 184,
    code: "PW",
    name: "Palau",
  },
  {
    id: 185,
    code: "PY",
    name: "Paraguay",
  },
  {
    id: 186,
    code: "QA",
    name: "Qatar",
  },
  {
    id: 187,
    code: "RE",
    name: "Réunion",
  },
  {
    id: 188,
    code: "RO",
    name: "Romania",
  },
  {
    id: 189,
    code: "RS",
    name: "Serbia",
  },
  {
    id: 190,
    code: "RU",
    name: "Russian Federation",
  },
  {
    id: 191,
    code: "RW",
    name: "Rwanda",
  },
  {
    id: 192,
    code: "SA",
    name: "Saudi Arabia",
  },
  {
    id: 193,
    code: "SB",
    name: "Solomon Islands",
  },
  {
    id: 194,
    code: "SC",
    name: "Seychelles",
  },
  {
    id: 195,
    code: "SD",
    name: "Sudan",
  },
  {
    id: 196,
    code: "SE",
    name: "Sweden",
  },
  {
    id: 197,
    code: "SG",
    name: "Singapore",
  },
  {
    id: 198,
    code: "SH",
    name: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    id: 199,
    code: "SI",
    name: "Slovenia",
  },
  {
    id: 200,
    code: "SJ",
    name: "Svalbard and Jan Mayen",
  },
  {
    id: 201,
    code: "SK",
    name: "Slovakia",
  },
  {
    id: 202,
    code: "SL",
    name: "Sierra Leone",
  },
  {
    id: 203,
    code: "SM",
    name: "San Marino",
  },
  {
    id: 204,
    code: "SN",
    name: "Senegal",
  },
  {
    id: 205,
    code: "SO",
    name: "Somalia",
  },
  {
    id: 206,
    code: "SR",
    name: "Suriname",
  },
  {
    id: 207,
    code: "SS",
    name: "South Sudan",
  },
  {
    id: 208,
    code: "ST",
    name: "São Tomé and Príncipe",
  },
  {
    id: 209,
    code: "SV",
    name: "El Salvador",
  },
  {
    id: 210,
    code: "SX",
    name: "Sint Maarten (Dutch part)",
  },
  {
    id: 211,
    code: "SY",
    name: "Syria",
  },
  {
    id: 212,
    code: "SZ",
    name: "Swaziland",
  },
  {
    id: 213,
    code: "TC",
    name: "Turks and Caicos Islands",
  },
  {
    id: 214,
    code: "TD",
    name: "Chad",
  },
  {
    id: 215,
    code: "TF",
    name: "French Southern Territories",
  },
  {
    id: 216,
    code: "TG",
    name: "Togo",
  },
  {
    id: 217,
    code: "TH",
    name: "Thailand",
  },
  {
    id: 218,
    code: "TJ",
    name: "Tajikistan",
  },
  {
    id: 219,
    code: "TK",
    name: "Tokelau",
  },
  {
    id: 220,
    code: "TM",
    name: "Turkmenistan",
  },
  {
    id: 221,
    code: "TN",
    name: "Tunisia",
  },
  {
    id: 222,
    code: "TO",
    name: "Tonga",
  },
  {
    id: 223,
    code: "TL",
    name: "Timor-Leste",
  },
  {
    id: 224,
    code: "TR",
    name: "Turkey",
  },
  {
    id: 225,
    code: "TT",
    name: "Trinidad and Tobago",
  },
  {
    id: 226,
    code: "TV",
    name: "Tuvalu",
  },
  {
    id: 227,
    code: "TW",
    name: "Taiwan",
  },
  {
    id: 228,
    code: "TZ",
    name: "Tanzania",
  },
  {
    id: 229,
    code: "UA",
    name: "Ukraine",
  },
  {
    id: 230,
    code: "UG",
    name: "Uganda",
  },
  {
    id: 231,
    code: "GB",
    name: "United Kingdom",
  },
  {
    id: 232,
    code: "UM",
    name: "USA Minor Outlying Islands",
  },
  {
    id: 235,
    code: "UZ",
    name: "Uzbekistan",
  },
  {
    id: 236,
    code: "VA",
    name: "Holy See (Vatican City State)",
  },
  {
    id: 237,
    code: "VC",
    name: "Saint Vincent and the Grenadines",
  },
  {
    id: 238,
    code: "VE",
    name: "Venezuela",
  },
  {
    id: 239,
    code: "VG",
    name: "Virgin Islands (British)",
  },
  {
    id: 240,
    code: "VI",
    name: "Virgin Islands (USA)",
  },
  {
    id: 241,
    code: "VN",
    name: "Vietnam",
  },
  {
    id: 242,
    code: "VU",
    name: "Vanuatu",
  },
  {
    id: 243,
    code: "WF",
    name: "Wallis and Futuna",
  },
  {
    id: 244,
    code: "WS",
    name: "Samoa",
  },
  {
    id: 245,
    code: "YE",
    name: "Yemen",
  },
  {
    id: 246,
    code: "YT",
    name: "Mayotte",
  },
  {
    id: 247,
    code: "ZA",
    name: "South Africa",
  },
  {
    id: 248,
    code: "ZM",
    name: "Zambia",
  },
  {
    id: 249,
    code: "ZW",
    name: "Zimbabwe",
  },
  {
    id: 250,
    code: "XK",
    name: "Kosovo",
  },
  {
    id: 234,
    code: "UY",
    name: "Uruguay",
  },
  {
    id: 170,
    code: "NZ",
    name: "New Zealand",
  },
  {
    id: 233,
    code: "US",
    name: "United States",
  },
  {
    id: 13,
    code: "AU",
    name: "Australia",
  },
  {
    id: 18,
    code: "BB",
    name: "Barbados",
  },
];
