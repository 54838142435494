import axios from "axios";
import _ from "lodash";
import {
  PRODUCTS,
  CATEGORIES,
  SEARCH,
  AUTOCOMPLETE,
  ACCOUNT,
  DETAILS,
} from "../../constants/apiLink";

export const apiAutoComplete = async (
  searchTerm,
  level = null,
  searchTerm2,
  type,
  dash,
  selectedHoseBrand,
  selectedHoseSeries
) => {
  // let url = PRODUCTS + AUTOCOMPLETE + `?searchTerm=${searchTerm}`;
  let url = PRODUCTS + `?SearchParam=${searchTerm}`;
  if (searchTerm2 !== "") url = url + `&SearchParam2=${searchTerm2}`;
  // if (level) {
  //   level = _.replace(level, "&", "$and$");
  //   url = `${url}&level=${level}`;
  // }

  if (level) {
    level = _.replace(level, new RegExp(",", "g"), "/");
    url = `${url}&category=${level}`;
  }

  if (type !== "") {
    url = `${url}&Type=${type}`
  }

  if (dash !== "") {
    url = `${url}&Dash=${dash}`
  }

  if (selectedHoseBrand !== "") {
    url = `${url}&SelectedHoseBrand=${selectedHoseBrand}`
  }

  if (selectedHoseSeries !== "") {
    url = `${url}&SelectedHoseSeries=${selectedHoseSeries}`
  }

  url = `${url}&Limit=200`

  return await axios.get(url);
};

export const apiSearch = async (
  searchTerm,
  level,
  activePage = 1,
  activeNode = null,
  threadType1,
  threadSize1,
  threadType2,
  threadSize2,
  type,
  dashSize,
  hoseBrand,
  hoseSeries,
  size = 10
) => {
  let url = PRODUCTS + `?SearchParam=${searchTerm}`;
  let filter = ``;
  if (level) {
    // level = _.replace(level, new RegExp(",", "g"), "/");
    url = `${url}&category=${level}`;
  }
  if (activePage) {
    url = `${url}&PageNo=${activePage}`;
  }

  if (type !== "") {
    url = `${url}&Type=${type}`
  }

  if (dashSize !== "") {
    url = `${url}&Dash=${dashSize}`
  }

  if (hoseBrand !== "") {
    url = `${url}&SelectedHoseBrand=${hoseBrand}`
  }

  if (hoseSeries !== "") {
    url = `${url}&SelectedHoseSeries=${hoseSeries}`
  }
  // if (threadType1) {
  //   filter = `&threadType1=${threadType1}`;
  // }
  // if (threadSize1) {
  //   filter = `${filter}&threadSize1=${threadSize1}`;
  // }
  // if (threadType2) {
  //   filter = `${filter}&threadType2=${threadType2}`;
  // }
  // if (threadSize2) {
  //   filter = `${filter}&threadSize2=${threadSize2}`;
  // }
  url = `${url}${filter}&size=${size}`;

  return await axios.get(url);
};

export const apiDetailSearch = async (item) => {
  let url = PRODUCTS + `${item.id}`;
  return await axios.get(url);
};

export const apiGetCategoriesTree = async () => {
  let url = PRODUCTS + CATEGORIES;
  const data = {
    withImages: true,
  };
  const response = await axios.get(url, { params: data });
  return response.data;
};

export const apiGetUserInfo = () => {
  let url = ACCOUNT + DETAILS;
  return axios.get(url, (result) => {
    return result.data;
  });
};

export const apiGetBoxSizePriceDiscount = (data) => {
  let url = "/pricingPolicy" + "/getBoxPriceDiscount";
  return axios.post(url, data).then((result) => {
    return result.data;
  });
};
