import axios from "axios";
import { showErrorToast } from "../../utils/toastUtils";

export const apiGetCustomersList = (keyword, searchType, limit = 1000) => {
  keyword = keyword !== "" ? keyword : null;
  const data = {
    PageNo: 1,
    Limit: limit,
    SearchParam: keyword,
    SearchType: searchType,
  };
  return axios
    .get("/api/user/user-customers-list", { params: data })
    .then((result) => {
      if (result) {
        if (result.data) {
          const data = result.data.customersWithContacts;
          data.map((item) => {
            item.text = item.companyName ? item.companyName : "";
            item.value = item.id;
          });
          return data;
        }
      }
      return false;
    });
};

export const apiGetCustomer = (id) => {
  return axios.get(`/api/user/customer-contacts/${id}`).then((result) => {
    if (result && result.data) {
      let data = result.data;
      if (data.discountPercent)
        data.discountPercent = Math.round(data.discountPercent * 10) / 10;
      if (data.useGlobalLabour === null && data.labourFee === null)
        data.useGlobalLabour = true;
      if (data.useGlobalTravel === null && data.travelFee === null)
        data.useGlobalTravel = true;
      if (data.useGlobalMarkup === null && data.markupPercent === null)
        data.useGlobalMarkup = true;
      return data;
    }
    return false;
  });
};

export const apiAddCustomer = async (data, userName) => {
  data.userName = userName;
  return axios
    .post(`/api/user/customer`, data)
    .then((result) => {
      console.log(result);
      if (result && result.data) {
        return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response) {
        if (error.response.status === 403)
          showErrorToast("A Customer with this name already exists");
      } else {
        console.log("Error:", error.message);
      }
    });
};

export const apiAddContact = async (data, id) => {
  data.customerId = id;
  return axios.post(`/api/user/customer-contacts`, data).then((result) => {
    if (result && result.data) {
      return result.data;
    }
    return false;
  });
};

export const apiUpdateCustomer = async (data) => {
  if (data.labourFee) data.labourFee = Number(data.labourFee);
  if (data.travelFee) data.travelFee = Number(data.travelFee);
  if (data.markupPercent) data.markupPercent = Number(data.markupPercent);
  if (data.discountPercent) data.discountPercent = Number(data.discountPercent);
  if (data.labourFee === "") data.labourFee = null;
  if (data.travelFee === "") data.travelFee = null;
  if (data.markupPercent === "") data.markupPercent = null;
  if (data.discountPercent === "") data.discountPercent = null;
  return axios.put(`/api/user/update-customer`, data).then((result) => {
    if (result && result.status) {
      if (result.status === 200 || result.status === 204) return data;
    }
    return false;
  });
};

export const apiUpdateContact = async (data) => {
  return axios
    .put(`/api/user/update-customercontact/${data.id}`, data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return data;
      }
      return false;
    });
};

export const apiDeleteContact = async (data) => {
  return axios
    .delete(`/api/user/delete-customercontact/${data.id}`, data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return data;
      }
      return false;
    });
};

export const apiSaveCustomersAndContacts = async (data) => {
  const postData = { customersWithContacts: data };
  return axios
    .post(`/api/user/customer-contacts-bulk`, postData)
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};
