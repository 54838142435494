export const hoseMakerTempData = {
  step1_Hose: 0,
  step1_Barcode: "",
  step1_Name: "",
  step2_LHF: 0,
  step2_Barcode: "",
  step2_Name: "",
  step2_Matched: false,
  step3_LFerrule: 0,
  step3_Barcode: "",
  step3_Name: "",
  step3_Matched: false,
  step4_RHF: 0,
  step4_Barcode: "",
  step4_Name: "",
  step4_Matched: false,
  step5_RFerrule: 0,
  step5_Barcode: "",
  step5_Name: "",
  step5_Matched: false,
  quantity: 1,
  orientation: 0,
  oal: 0,
  positionId: 0,
  others: [],
  lfActualCrimpSize: 0,
  rfActualCrimpSize: 0,
  isMarkedPushedOnCorrectly: false,
  bomAssembly: {
    lfActualCrimpSize: 0,
    lfRecCrimpSize: 0,
    lfRecDieSize: 0,
    rfActualCrimpSize: 0,
    rfRecCrimpSize: 0,
    rfRecDieSize: 0,
    isMarkedPushedOnCorrectly: false,
    image1: "",
    image2: "",
    image3: "",
    image4: "",
  },
};
