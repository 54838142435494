export const customerSearchDefaultOptions = [
  {
    key: "All",
    value: "All",
  },
  {
    key: "CompanyName",
    value: "Company Name",
  },
  {
    key: "ContactName",
    value: "Contact Name",
  },
  {
    key: "Email",
    value: "Email",
  },
  {
    key: "ContactNumber",
    value: "Contact Number",
  },
];
