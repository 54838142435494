export const CREATE_ACCOUNT = "/createAccount";
export const LOGIN = "/";
export const FORGOT_PASSWORD = "/forgotPassword";
export const VERIFY_EMAIL = "/verify-email/:code";
export const RESET_PASSWORD = "/reset-password/: ";
export const CREATE_PASSWORD = "/create-password/:code/:email";
export const VERIFY_NEW_EMAIL = "/verifyNewEmail/:email/:code";
export const SERVER_ERROR_PAGE = "/error";
export const ACCEPT_QUOTE = "/acceptQuote";

export const HOME = "/home";
export const SEARCH = "/search";
export const PROFILE = "/profile";
export const HOSE_MAKER = "/hoseMaker";
export const HOSE_MAKER2 = "/jobbing/hoseMaker";
export const KITCHEN_SINK = "/kitchenSink";
export const APPLICATION_FORM = "/profile/creditApplicationForm";
export const DELIVERY_ADDRESS = "/profile/deliveryAddress";
export const CHANGE_ADDRESS = "/profile/changePassword";
export const CART = "/cart";
export const CHAT = "/chat";
export const NEW_GROUP = "/newgroup";
export const ORDER_CONFIRMATION = "/orderConfirmation";
export const PAYMENT_RESULT = "/paymentResult";
export const ORDER_HISTORY = "/orderHistory";
export const MY_FAVORITES = "/myFavorites";
export const ASSET = "/asset";
export const MANAGE_ASSET = "/manageAsset";
export const ASSET_LOCATION = "/asset/location";
export const CREW = "/crew";
export const INVENTORY = "/inventory";
export const STOCK_FORM = "/stock";
export const STOCK_REVIEW = "/stockReview";
export const INVENTORY_LOCATION = "/inventory/location";
export const SCHEDULE_MAINTENANCE = "/schedule";
export const MAINTENANCE_FORM = "/maintenanceForm";
export const ADD_MAINTENANCE_FORM = "/addMaintenanceForm";
export const EDIT_MAINTENANCE_FORM = "/editMaintenanceForm/:id";
export const AUDIT_TRAIL = "/audit";
export const ASSET_DETAILS = "/assetDetails/:id";
export const REVIEW_MAINTENANCE = "/maintenance/review/:id";
export const MY_HOSES = "/myHoses";
export const HOSE_MECHANIC = "/hoseMechanic";
export const CHECK_REVIEW = "/maintenance/check/:id";
export const SAVING_GRAPH = "/savingsGraph";
export const PICKUP_ADDRESS = "/pickUpAddress";
export const JOBBING = "/jobbing";
export const SETTINGS = "/settings";
export const COMPANYRANKING = "/company-ranking";
export const BOAPOD = "/find-boapod";
export const MYASSETS = "/my-assets";
export const CALLBACK = "/callback";
export const ORDER_HISTORY_NEW = "/order-history";
export const HOSEMAP = "/hose-map";
export const MY_RESOURCES = "/my-resources";
export const BOA_HIS = "/boa-his";
export const INVENTORY_MANAGEMENT = "/inventory-management";
export const STOCK_TRANSFER = "/stock-transfer";
export const STOCK_TRANSFER_PRODUCT = "/stock-transfer/:from/:to/:product";
export const STOCK_ADJUSTMENT = "/stock-adjustment";
export const QUOTING = "/quoting";
export const MY_CUSTOMERS = "/my-customers";
