import _ from "lodash";
import React, { Component } from "react";
import { Dropdown, Search } from "semantic-ui-react";

class AssetSearch extends Component {
  constructor() {
    super();
  }

  state = {
    open: false,
  };

  // Debounce the onSearchChange handler
  onSearchChange = _.debounce((e, { value }) => {
    // Update the state with the current search value
    this.props.onSearchChange(value);
  }, 1000); // Adjust the debounce delay as needed

  handleFocus = () => {
    this.setState({ open: true }); // Show all results on focus
  };

  handleBlur = () => {
    // Delay closing to allow `onResultSelect` to trigger
    this.timeout = setTimeout(() => {
      this.setState({ open: false });
    }, 200);
  };

  resultRenderer = ({ value, title, description, price }) => {
    return (
      <div>
        <span style={{ fontSize: 16, fontWeight: "bolder" }}>{value}</span>
        <br />
        <span style={{ color: "#808080" }}>Manufacturer: {price}</span>
        <br />
        <span style={{ color: "#808080" }}>Equipment Type: {description}</span>
        <br />
        <span style={{ color: "#808080" }}>Model: {title}</span>
      </div>
    );
  };

  render() {
    const {
      loading,
      value,
      enable,
      onResultSelect,
      onSearchChange,
      noResultsMessage,
      isMyAssets = false,
      results,
    } = this.props;

    return (
      <>
        {isMyAssets ? (
          <Search
            className="search-box"
            loading={loading}
            placeholder={loading ? "" : "Select from your asset list"}
            onResultSelect={onResultSelect}
            onSearchChange={onSearchChange}
            noResultsMessage={noResultsMessage}
            value={value}
            style={{
              opacity: enable ? "1" : "0.6",
              pointerEvents: enable ? "" : "none",
              width: "100%",
            }}
            results={results}
            onFocus={this.handleFocus}
            open={this.state.open}
            onBlur={this.handleBlur}
            resultRenderer={this.resultRenderer}
          />
        ) : (
          <Search
            className="search-box"
            loading={loading}
            placeholder={loading ? "" : "Search Asset Details"}
            onResultSelect={onResultSelect}
            onSearchChange={onSearchChange}
            noResultsMessage={noResultsMessage}
            value={value}
            style={{
              opacity: enable ? "1" : "0.6",
              pointerEvents: enable ? "" : "none",
            }}
            {...this.props}
          />
        )}
      </>
    );
  }
}

export default AssetSearch;
