import React, { useEffect, useState } from "react";
import { Grid, Loader, Step } from "semantic-ui-react";
import { FadeIn } from "react-animated-components";
import { apiAcceptQuote } from "../Quoting/quoting.api";
import boaLogo from "../../assets/images/boa_icon.png";
import assetsImage from "../../assets/images/pages/my-assets.png";
import boaHISImage from "../../assets/images/pages/boa-his.png";

const AcceptQuote = ({ location }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState(false);
  const [invalidQuoteDetails, setInvalidQuoteDetails] = useState(false);

  const navigationTiles = [
    {
      name: "Manage your assets",
      imgSrc: assetsImage,
      href: "https://boahydraulics.com/asset-management"
    },
    {
      name: "Learn more about BOAhub",
      imgSrc: boaHISImage,
      href: "https://boahydraulics.com/boa-hub "
    }
  ];

  useEffect(() => {
    console.log(location);
    const quoteId = new URLSearchParams(location.search).get("quoteId");
    const createdDate = new URLSearchParams(location.search).get("createdDate");
    if (!quoteId || !createdDate) {
      setInvalidQuoteDetails(true);
      setIsLoading(false);
    } else {
      const delayDebounceFn = setTimeout(() => {
        onMoveQuoteToJobbing(quoteId, createdDate);
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, []);

  const onMoveQuoteToJobbing = async (quoteId, createdDate) => {
    const result = await apiAcceptQuote(quoteId, createdDate);
    if (result) {
      if (result.status === 204) {
        setData(result);
        setIsSuccess(true);
        setIsLoading(false);
      } else {
        setData(result);
        setIsSuccess(false);
        setIsLoading(false);
      }
    } else {
      setIsSuccess(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          marginTop: isLoading ? 100 : 0,
          marginLeft: "auto",
          marginRight: "auto",
          height: 550,
        }}
      >
        {isLoading ? (
          <Loader active className="workaround" size="large" inline="centered">
            Loading, please wait...
          </Loader>
        ) : isSuccess ? (
          <FadeIn fade durationMs={600}>
            <Grid>
              <Grid.Row verticalAlign="middle">
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    marginTop: -10,
                    marginBottom: 30,
                  }}
                >
                  <img src={boaLogo} style={{ width: 150, height: 73.56 }} />
                </div>
                <div
                  style={{
                    margin: "0px auto",
                    minHeight: "200px",
                    textAlign: "center",
                    marginTop: 20,
                  }}
                >
                  <h1>Success!</h1>
                  <h3>Quote has been accepted.</h3>
                  {!window.localStorage.getItem("user") && (
                    <>
                      <p>Login to BOAhub for more details.</p>
                      <br />
                    </>
                  )}
                </div>
              </Grid.Row>
            </Grid>
          </FadeIn>
        ) : (
          <FadeIn fade durationMs={600}>
            <Grid>
              {invalidQuoteDetails && (
                <Grid.Row verticalAlign="middle">
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      marginTop: -10,
                      marginBottom: 30,
                    }}
                  >
                    <img src={boaLogo} style={{ width: 150, height: 73.56 }} />
                  </div>
                  <div
                    style={{
                      margin: "0px auto",
                      minHeight: "200px",
                      textAlign: "center",
                      marginTop: 20,
                    }}
                  >
                    <h1>Error</h1>
                    <h3>Unable to access this page.</h3>
                  </div>
                </Grid.Row>
              )}
              {!invalidQuoteDetails && (
                <Grid.Row verticalAlign="middle">
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      marginTop: -10,
                      marginBottom: 30,
                    }}
                  >
                    <img src={boaLogo} style={{ width: 150, height: 73.56 }} />
                  </div>
                  <div
                    style={{
                      margin: "0px auto",
                      minHeight: "185px",
                      textAlign: "center",
                      marginTop: 20,
                    }}
                  >
                    <h1>Error</h1>
                    <h3>Unable to accept the quote.</h3>
                    <p>{data.description}</p>
                  </div>
                </Grid.Row>
              )}
            </Grid>
          </FadeIn>
        )}
        {!isLoading && (
          <Grid
            centered
          >
            {navigationTiles.map((tile) => (
              <Grid.Column width={4}>
                <Step.Group
                  style={{
                    width: "100%",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    border: "3px solid #000000",
                    borderRadius: "5px"
                  }}
                >
                  <Step
                    onClick={() => window.open(tile.href, "_blank")}
                  >
                    <img
                      src={tile.imgSrc}
                      style={{
                        height: "120px",
                        width: "auto",
                      }}
                    />
                  </Step>
                </Step.Group>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "1.3rem",
                    color: "#66CC23",
                    fontWeight: 600
                    }}
                >
                  {tile.name}
                </p>
              </Grid.Column>
            ))}
          </Grid>
        )}
      </div>
    </>
  );
};

export default AcceptQuote;
