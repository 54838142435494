import React from "react";
import FamilyBreadCrumb from "./FamilyBreadCrumb";
import PropTypes from "prop-types";

const resultRenderer = ({
  name: stockDescription,
  barcode: stockCode,
  price,
  type,
  secondaryGrpDisplayName,
  tertiaryGrpDisplayName,
  productFamilyDisplayName,
  isMatched
}) => {
  return (
    <div key={stockDescription}>
      <div>
        <b>({stockCode}) </b> {stockDescription}{" "}
        {isMatched && <p style={{ color: "#66CB23", fontWeight: 700, marginTop: "0.5rem" }}>Matched</p>}
      </div>
      {(secondaryGrpDisplayName || tertiaryGrpDisplayName || productFamilyDisplayName) && (
        <div>
          <FamilyBreadCrumb
            key={stockCode}
            {...{
              secondaryGrpDisplayName,
              tertiaryGrpDisplayName,
              productFamilyDisplayName,
            }}
          />{" "}
        </div>
      )}
    </div>
  );
};

resultRenderer.propTypes = {
  stockDescription: PropTypes.string,
  stockCode: PropTypes.string,
  price: PropTypes.number,
  type: PropTypes.string,
  secondaryGrpDisplayName: PropTypes.string,
  tertiaryGrpDisplayName: PropTypes.string,
  productFamilyDisplayName: PropTypes.string,
  isMatched: PropTypes.bool
};

export default resultRenderer;
