import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Card,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Image,
  Loader,
  Button,
  Modal,
  Search,
  Select,
  Table,
  TextArea,
} from "semantic-ui-react";
import "./index.css";
import MetaData from "../../components/MetaData";
import { useDraggable } from "react-use-draggable-scroll";
import _debounce from "lodash/debounce";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import cartReducer from "../Cart/cart.reducer";
import searchReducer from "../Search/search.reducer";
import myCustomersIcon from "../../assets/images/pages/my-customers.png";
import {
  apiAddContact,
  apiAddCustomer,
  apiDeleteContact,
  apiGetCustomer,
  apiGetCustomersList,
  apiSaveCustomersAndContacts,
  apiUpdateContact,
  apiUpdateCustomer,
} from "./myCustomers.api";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import rightArrow from "../../../src/assets/images/right-transparent.png";
import Papa from "papaparse";
import { customerSearchDefaultOptions } from "./data";

const StockTransfer = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [customerList, setCustomerList] = useState([]);
  const [formData, setFormData] = useState({ statusId: 0 });
  const [status, setStatus] = useState("In Progress");
  const [addContactOpen, setAddContactOpen] = useState(false);
  const [isAddCompany, setIsAddCompany] = useState(false);
  const [contactFormData, setContactFormData] = useState({});
  const [contactSaved, setContactSaved] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [onCustomerEdit, setOnCustomerEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openRemoveContact, setOpenRemoveContact] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [jsonResult, setJsonResult] = useState([]);
  const [jsonResultData, setJsonResultData] = useState([]);
  const [customersLoading, setCustomersLoading] = useState(false);
  const [customerSearchOptions, setCustomerSearchOptions] = useState(
    customerSearchDefaultOptions
  );
  const [customerSearchKeyword, setCustomerSearchKeyword] = useState("");
  const [customerSearchType, setCustomerSearchType] = useState(null);
  const [customerSearchTypeFullName, setCustomerSearchTypeFullName] =
    useState(null);

  const csvRef = useRef();

  const ref = useRef();
  const { events } = useDraggable(ref);

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const [openSearchList, setOpenSearchList] = useState(false);

  const handleCustomerSearchChange = (e) => {
    setOpenSearchList(true);
    if (e.target.value === "" && customerSearchType !== null) {
      setCustomerSearchOptions(customerSearchDefaultOptions);
      setCustomerSearchType(null);
      setCustomerSearchTypeFullName(null);
      getCustomersList("", null);
      setOpenSearchList(false);
    }
    const updatedOptions = customerSearchDefaultOptions.map((item) => ({
      ...item,
      value: (
        <span
          style={{
            color: "#grey",
            fontWeight: "normal",
            fontSize: 16,
          }}
        >
          Search by {item.value}:{" "}
          <span
            style={{
              color: "#000",
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            {e.target.value}
          </span>
        </span>
      ),
    }));
    setCustomerSearchKeyword(e.target.value);
    setCustomerSearchOptions(updatedOptions);
  };

  const handleCustomerResultSelect = (e, { result }) => {
    const _type = result.key;
    const _typeFullName = result.title.props.children[1];
    setOpenSearchList(false);
    setCustomerSearchType(_type);
    setCustomerSearchTypeFullName("Search by " + _typeFullName);
    getCustomersList(customerSearchKeyword, _type);
  };

  const onHandleKeyPress = (e) => {
    if (e.key === "Enter" && customerSearchOptions.length > 0) {
      e.preventDefault(); // Prevent default Enter key behavior
      const _type = "All";
      const _typeFullName = "All";

      setOpenSearchList(false);

      setCustomerSearchType(_type);
      setCustomerSearchTypeFullName("Search by " + _typeFullName);
      getCustomersList(customerSearchKeyword, _type);
    }
  };

  function handleChange(dataType, value) {
    let newState = [];
    newState.push(formData);
    let data = newState.map((item, i) => {
      if (i == 0) {
        if (dataType == "useGlobalLabour" && value) {
          item.labourFee = "";
        }
        if (dataType == "useGlobalTravel" && value) {
          item.travelFee = "";
        }
        if (dataType == "useGlobalMarkup" && value) {
          item.markupPercent = "";
        }
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setFormData(data[0]);
  }

  function handleContactChange(dataType, value) {
    let newState = [];
    newState.push(contactFormData);
    let data = newState.map((item, i) => {
      if (i == 0) {
        if (dataType === "companyName" && value) {
          item.id = 0;
          item.customerId = 0;
        }
        return { ...item, [dataType]: value };
      }
      return item;
    });
    setContactFormData(data[0]);
  }

  const getCustomersList = async (keyword = "", searchType = null) => {
    setSearchLoading(true);
    const companies = await apiGetCustomersList(keyword, searchType);
    if (companies) {
      setCustomers(companies);
      const UNASSIGNED_KEY = "Unassigned Contacts"; // Define the final key name

      const groupedCompanies = companies.reduce((acc, company) => {
        const firstLetter =
          company.companyName === "Unassigned"
            ? UNASSIGNED_KEY
            : company.companyName[0].toUpperCase();

        acc[firstLetter] = acc[firstLetter] || [];
        acc[firstLetter].push(company);
        return acc;
      }, {});

      // Sort keys, ensuring "Unassigned Contacts" is always last
      const sortedGroupedCompanies = Object.keys(groupedCompanies)
        .filter((key) => key !== UNASSIGNED_KEY) // Exclude "Unassigned Contacts" from normal sorting
        .sort() // Sort A-Z
        .concat(UNASSIGNED_KEY) // Append "Unassigned Contacts" at the end
        .reduce((sortedAcc, key) => {
          sortedAcc[key] = groupedCompanies[key];
          return sortedAcc;
        }, {});

      console.log(sortedGroupedCompanies);
      setCustomerList(sortedGroupedCompanies);
      setSearchLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setSearchLoading(false);
    }
  };

  const getCustomerData = async () => {
    setDataLoading(true);
    const result = await apiGetCustomer(selectedId);
    if (result) {
      setOnCustomerEdit(false);
      setFormData(result);
      setDataLoading(false);
    } else {
      showErrorToast("Failed to load Customer, please try again");
      setDataLoading(false);
    }
  };

  const onSubmitAddCustomer = async () => {
    setContactSaved(true);
    if (
      (!contactFormData.companyName || contactFormData.companyName === "") &&
      isAddCompany
    ) {
      showErrorToast("Please complete all required fields");
      return false;
    }
    if (!contactFormData.customerId && !isAddCompany) {
      showErrorToast("Please complete all required fields");
      return false;
    }
    const userName = localStorage.getItem("user");
    if (contactFormData.customerId) {
      if (contactFormData.contactName) {
        if (contactFormData.contactNumber) {
          const phoneValid = isValidPhoneNumber(
            contactFormData.contactNumber ? contactFormData.contactNumber : ""
          );
          if (!phoneValid) {
            showErrorToast("Invalid phone number");
            return false;
          }
        }
        if (contactFormData.email) {
          contactFormData.email = contactFormData.email.trim().toLowerCase();
          if (!isValidEmail(contactFormData.email)) {
            showErrorToast("Invalid email address");
            return false;
          }
        }
      } else {
        showErrorToast("Please complete all required fields");
        return false;
      }
      return onSubmitAddContact(contactFormData.customerId);
    }
    try {
      const contactData = await apiAddCustomer(contactFormData, userName);
      if (contactData) {
        if (contactFormData.contactName) {
          onSubmitAddContact(contactData.id);
        } else {
          getCustomerData();
          setAddContactOpen(false);
          setContactFormData({});
          setContactSaved(false);
          showSuccessToast(
            <div>
              <i className="check icon" /> Customer Added Successfully
            </div>
          );
          setIsOpen(false);
          setIsLoading(false);
        }
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onSubmitAddContact = async (id) => {
    try {
      const contactData = await apiAddContact(contactFormData, id);
      if (contactData) {
        getCustomersList();
        getCustomerData();
        setAddContactOpen(false);
        setContactFormData({});
        showSuccessToast(
          <div>
            <i className="check icon" /> Contact Added Successfully
          </div>
        );
        setIsOpen(false);
        setIsLoading(false);
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onSubmitUpdateCustomer = async () => {
    try {
      const contactData = await apiUpdateCustomer(formData);
      if (contactData) {
        getCustomersList();
        getCustomerData();
        setAddContactOpen(false);
        setContactFormData({});
        showSuccessToast(
          <div>
            <i className="check icon" /> Customer Updated Successfully
          </div>
        );
        setIsOpen(false);
        setIsLoading(false);
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onSubmitUpdateContact = async () => {
    try {
      const contactData = await apiUpdateContact(contactFormData);
      if (contactData) {
        getCustomersList();
        getCustomerData();
        setAddContactOpen(false);
        setContactFormData({});
        showSuccessToast(
          <div>
            <i className="check icon" /> Contact Updated Successfully
          </div>
        );
        setIsOpen(false);
        setIsLoading(false);
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onDeleteContact = async () => {
    setIsLoading(true);
    try {
      const contactData = await apiDeleteContact(selectedContact);
      if (contactData) {
        getCustomersList();
        getCustomerData();
        showSuccessToast(
          <div>
            <i className="check icon" /> Contact Deleted Successfully
          </div>
        );
        setSelectedContact({});
        setOpenRemoveContact(false);
        setIsLoading(false);
      } else {
        showErrorToast("Something went wrong");
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const userName = localStorage.getItem("user");
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          let resultData = result.data;

          resultData.map(async (customer) => {
            customer.id = 0;
            customer.isDeleted = false;
            customer.userName = userName;
            customer.companyName = customer["Customer Name"];
            customer.customerName = customer["Customer Name"];
            customer.contactName = customer["Contact Name"];
            customer.contactNumber = customer["Contact Number"];
            customer.email = customer["Email Address"];
            if (customer.email) {
              customer.email = customer.email.trim().toLowerCase();
              if (!isValidEmail(customer.email)) {
                customer.hasInvalidEmail = true;
              }
            }
          });

          console.log(resultData);

          let data = resultData.filter((obj) => {
            return (
              (obj.customerName !== undefined &&
                obj.customerName !== null &&
                obj.customerName !== "") ||
              (obj.contactName !== undefined &&
                obj.contactName !== null &&
                obj.contactName !== "") ||
              (obj.contactNumber !== undefined &&
                obj.contactNumber !== null &&
                obj.contactNumber !== "") ||
              (obj.email !== undefined &&
                obj.email !== null &&
                obj.email !== "")
            );
          });

          let list = data.reduce((result, currentItem) => {
            currentItem.customerId = 0;

            const group = result.find(
              (groupItem) => groupItem.companyName === currentItem.companyName
            );

            if (group) {
              group.isDeleted = false;
              group.userName = userName;
              group.customerContacts.push(currentItem);
            } else {
              result.push({
                isDeleted: false,
                userName: userName,
                companyName: currentItem.companyName,
                customerContacts: [currentItem],
              });
            }

            return result;
          }, []);

          setJsonResultData(list);
          setJsonResult(data);
        },
        header: true, // Set this to true if your CSV file has headers
      });
    }
  };

  const generateCSV = () => {
    const csvContent = [
      ["Customer Name", "Contact Name", "Contact Number", "Email Address"],
      // Add more data rows here
    ];

    const csvRows = csvContent.map((row) => row.join(",")); // Join each row with a comma

    return csvRows.join("\n"); // Join rows with a newline character
  };

  const onSaveCustomersAndContacts = async () => {
    let hasError = false;
    jsonResult.map((data) => {
      if (!data.customerName || data.hasInvalidEmail) {
        hasError = true;
      }
    });

    if (hasError) {
      showErrorToast("Please correct all data before importing");
      return false;
    }

    let postData = [];

    // mapping for csv upload
    if (jsonResult.length > 0) {
      postData = jsonResultData;
    } else {
      postData = customers;
    }

    postData.map((data) => {
      if (data.discountPercent !== null)
        data.discountPercent = Number(data.discountPercent);
    });

    setIsLoading(true);
    setCustomersLoading(true);
    setOnCustomerEdit(false);
    try {
      const data = await apiSaveCustomersAndContacts(postData);
      if (data) {
        getCustomersList();
        getCustomerData();
        showSuccessToast(
          <div>
            <i className="check icon" /> Customers and Contacts Updated
            Successfully
          </div>
        );
        setCsvUploadOpen(false);
        setIsLoading(false);
        setJsonResult([]);
        setJsonResultData([]);
      } else {
        showErrorToast("Something went wrong");
        setCustomersLoading(false);
        setIsLoading(false);
      }
    } catch {
      showErrorToast("Something went wrong");
      setCustomersLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedId != "") {
      getCustomerData();
    }
  }, [selectedId]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      getCustomersList();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  const jobListGrouped = Object.entries(customerList).map(
    ([category, list], index) => (
      <div style={{ marginTop: index === 0 ? 0 : 18 }}>
        <span
          style={{
            fontSize: 18,
            marginLeft: 0,
            fontWeight: "bolder",
            color: "#66CC23",
          }}
        >
          {category}
        </span>
        {list.map((data, i) => {
          return (
            <Card
              className={
                selectedId === data?.id ? "job-card active" : "job-card"
              }
              style={{
                marginTop: 10,
                cursor: "default",
                minWidth: 185,
                borderRadius: 10,
                marginBottom: "0px !important",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.3)",
                position: "relative", // Ensure relative positioning
                cursor: "pointer",
              }}
            >
              <Card.Content
                onClick={() => {
                  if (selectedId !== data?.id) {
                    setSelectedId(data.id);
                  } else {
                    setSelectedId("");
                    setFormData(null);
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                <Grid columns={2} className="job-management">
                  <Grid.Row style={{ paddingTop: 5 }}>
                    <Grid.Column
                      width={13}
                      style={{ paddingTop: 6, paddingBottom: 6 }}
                    >
                      <b>
                        <Card.Description>{data.companyName}</Card.Description>
                      </b>
                    </Grid.Column>
                    <Grid.Column
                      width={3}
                      style={{
                        paddingTop: 6.5,
                        paddingBottom: 6,
                        paddingRight: 5,
                      }}
                    >
                      <div style={{ fontSize: 15, textAlign: "right" }}>
                        <Icon
                          style={{ marginRight: 8, color: "red" }}
                          link
                          name="trash"
                          onClick={() => {
                            setSelectedId(data?.id);
                            setTimeout(() => {
                              setDeleteStockTransferOpen(true);
                            }, 300);
                          }}
                        />
                      </div>
                    </Grid.Column>
                    <Grid.Column
                      width={16}
                      style={{
                        paddingTop: 10,
                        paddingBottom: 0,
                        paddingRight: 18,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 15,
                          textAlign: "right",
                          color: "#AFABAB",
                        }}
                      >
                        <Icon
                          style={{ marginRight: 8 }}
                          link
                          name="user"
                          onClick={() => {
                            setSelectedId(data?.id);
                            setTimeout(() => {
                              setDeleteStockTransferOpen(true);
                            }, 300);
                          }}
                        />
                        <span>{data.customerContacts.length}</span>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
          );
        })}
      </div>
    )
  );

  return (
    <React.Fragment>
      <MetaData title="My Customers" />
      <Modal size="tiny" open={addContactOpen}>
        <Modal.Header>
          {!contactFormData?.id ? "ADD NEW" : "EDIT"} CONTACT
        </Modal.Header>
        <Modal.Content>
          <Form size="tiny">
            {!contactFormData?.id ? (
              <>
                {!isAddCompany ? (
                  <Form.Field required>
                    <label>Company Name</label>
                    <Grid>
                      <Grid.Column width={12} style={{ paddingRight: 0 }}>
                        <Dropdown
                          className={
                            (contactFormData?.companyName === "" ||
                              contactFormData?.companyName === null ||
                              !contactFormData?.companyName) &&
                            !contactFormData?.customerId &&
                            contactSaved
                              ? "error"
                              : ""
                          }
                          placeholder="Company Name"
                          search
                          selection
                          options={customers}
                          style={{ width: "100%" }}
                          value={contactFormData.customerId}
                          onChange={(e, data) =>
                            handleContactChange("customerId", data.value)
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={4} style={{ paddingRight: 0 }}>
                        <Button
                          size="tiny"
                          onClick={() => {
                            handleContactChange("companyName", null);
                            setIsAddCompany(true);
                          }}
                        >
                          ADD NEW
                        </Button>
                      </Grid.Column>
                    </Grid>
                  </Form.Field>
                ) : (
                  <Form.Field required>
                    <label>Company Name</label>
                    <Grid>
                      <Grid.Column width={12} style={{ paddingRight: 0 }}>
                        <Input
                          className={
                            (contactFormData?.companyName === "" ||
                              contactFormData?.companyName === null) &&
                            contactSaved
                              ? "error"
                              : ""
                          }
                          placeholder="Company Name"
                          value={contactFormData?.companyName}
                          onChange={(e, data) =>
                            handleContactChange("companyName", data.value)
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={4} style={{ paddingRight: 0 }}>
                        <Button
                          size="tiny"
                          onClick={() => {
                            handleContactChange("companyName", null);
                            setIsAddCompany(false);
                          }}
                        >
                          CANCEL
                        </Button>
                      </Grid.Column>
                    </Grid>
                  </Form.Field>
                )}
              </>
            ) : null}
            <Form.Field>
              <label>Contact Name</label>
              <Input
                className={
                  contactSaved &&
                  contactFormData?.customerId &&
                  !contactFormData?.contactName
                    ? "error"
                    : ""
                }
                disabled={
                  (contactFormData?.companyName === "" ||
                    !contactFormData?.companyName) &&
                  !contactFormData?.customerId
                }
                placeholder="Contact Name"
                value={contactFormData?.contactName}
                onChange={(e, data) =>
                  handleContactChange("contactName", data.value)
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Phone Number</label>
              <PhoneInput
                disabled={
                  (contactFormData?.companyName === "" ||
                    !contactFormData?.companyName) &&
                  !contactFormData?.customerId
                }
                className={
                  !isValidPhoneNumber(
                    contactFormData?.contactNumber
                      ? contactFormData?.contactNumber
                      : ""
                  ) &&
                  contactSaved &&
                  contactFormData?.companyName &&
                  contactFormData?.contactNumber
                    ? "error"
                    : ""
                }
                international
                defaultCountry="NZ"
                countryCallingCodeEditable={false}
                placeholder="Phone Number"
                value={contactFormData?.contactNumber}
                onChange={(data) => handleContactChange("contactNumber", data)}
              />
            </Form.Field>
            <Form.Field>
              <label>Email Address</label>
              <Input
                disabled={
                  (contactFormData?.companyName === "" ||
                    !contactFormData?.companyName) &&
                  !contactFormData?.customerId
                }
                className={
                  !isValidEmail(
                    contactFormData?.email ? contactFormData?.email : ""
                  ) &&
                  contactSaved &&
                  contactFormData?.companyName &&
                  contactFormData?.email
                    ? "error"
                    : ""
                }
                placeholder="Email Address"
                value={contactFormData?.email}
                onChange={(e, data) => handleContactChange("email", data.value)}
              />
            </Form.Field>
            <Grid style={{ marginTop: 20 }}>
              <Grid.Column width={8} style={{ paddingRight: 5 }}>
                <Button
                  size="tiny"
                  style={{
                    width: "100%",
                    marginLeft: "auto",
                  }}
                  onClick={() => {
                    setAddContactOpen(false);
                    setContactSaved(false);
                    setContactFormData({});
                  }}
                >
                  CANCEL
                </Button>
              </Grid.Column>
              <Grid.Column width={8} style={{ paddingLeft: 5 }}>
                <Button
                  size="tiny"
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(102, 204, 35)",
                    color: "#fff",
                    marginLeft: "auto",
                  }}
                  onClick={() => {
                    if (!contactFormData.id) {
                      onSubmitAddCustomer();
                    } else {
                      onSubmitUpdateContact();
                    }
                  }}
                >
                  SAVE
                </Button>
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Content>
      </Modal>
      <Modal
        size="mini"
        open={openRemoveContact}
        onClose={() => setOpenRemoveContact(false)}
      >
        <div style={{ position: "absolute", bottom: 100, left: 150 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          <p>
            Are you sure <br /> you want to delete contact?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenRemoveContact(false)}>NO</Button>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => onDeleteContact()}
          >
            YES
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size="medium"
        open={csvUploadOpen}
        onClose={() => setCsvUploadOpen(false)}
      >
        <Modal.Header>IMPORT A FILE</Modal.Header>
        <Modal.Content>
          <Grid style={{ margin: 20, marginTop: 10, marginBottom: 0 }}>
            <Grid.Column
              width={16}
              style={{
                minHeight: 400,
                maxHeight: 400,
                overflowY: "auto",
              }}
            >
              <Table sortable celled fixed>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Customer</Table.HeaderCell>
                    <Table.HeaderCell>Contact</Table.HeaderCell>
                    <Table.HeaderCell>Phone Number</Table.HeaderCell>
                    <Table.HeaderCell>Email Address</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {jsonResult.map(
                    ({
                      customerName,
                      contactName,
                      contactNumber,
                      email,
                      hasInvalidEmail,
                    }) => (
                      <Table.Row key={contactName}>
                        <Table.Cell
                          style={{
                            border: !customerName ? "solid 1px red" : "",
                          }}
                        >
                          {!customerName ? (
                            <Popup
                              content={"Customer Name is required"}
                              trigger={<span>{customerName}</span>}
                              position="top center"
                              inverted
                            />
                          ) : (
                            customerName
                          )}
                        </Table.Cell>
                        <Table.Cell>{contactName}</Table.Cell>
                        <Table.Cell>{contactNumber}</Table.Cell>
                        <Table.Cell
                          style={{
                            border: hasInvalidEmail ? "solid 1px red" : "",
                          }}
                        >
                          {hasInvalidEmail ? (
                            <Popup
                              content={"Invalid email address"}
                              trigger={<span>{email}</span>}
                              position="top center"
                              inverted
                            />
                          ) : (
                            email
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column width={5} style={{ paddingRight: 5 }}>
              <Button
                size="small"
                onClick={() => {
                  const csvData = generateCSV();
                  const blob = new Blob([csvData], { type: "text/csv" });
                  const url = URL.createObjectURL(blob);
                  const a = document.createElement("a");
                  a.href = url;
                  a.download = "Template.csv";
                  a.click();
                  URL.revokeObjectURL(url);
                }}
              >
                DOWNLOAD TEMPLATE
              </Button>
            </Grid.Column>
            <Grid.Column
              width={11}
              style={{ paddingLeft: 5, textAlign: "right" }}
            >
              <Button
                size="small"
                color="youtube"
                onClick={() => {
                  setCsvUploadOpen(false);
                  setJsonResult([]);
                }}
                style={{ marginRight: 10 }}
              >
                CANCEL
              </Button>
              <Button size="small" onClick={() => csvRef.current.click()}>
                LOAD NEW FILE
              </Button>
              <input
                ref={csvRef}
                type="file"
                hidden
                onChange={handleFileChange}
              />
              <Button
                disabled={jsonResult.length === 0 || isLoading}
                size="small"
                style={{
                  backgroundColor: "rgb(102, 204, 35)",
                  color: "#fff",
                  marginLeft: "auto",
                }}
                onClick={() => onSaveCustomersAndContacts()}
              >
                {!isLoading ? "IMPORT" : "IMPORTING..."}
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
      <Grid
        columns={3}
        divided
        className="my-customers"
        style={{ marginTop: -30 }}
      >
        <Grid.Row style={{ paddingTop: 5, zIndex: 1 }}>
          <Grid.Column width={16} style={{ paddingRight: 0 }}>
            <h3 style={{ marginLeft: 20 }}>
              <img
                src={myCustomersIcon}
                style={{
                  width: 30,
                  height: 30,
                  marginTop: -5,
                  marginRight: 8,
                }}
              />
              My Customers
            </h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column
            width={4}
            className="transfer-list-section"
            style={{ paddingRight: 0 }}
          >
            <div
              className={
                customerSearchTypeFullName === null
                  ? "customer-search-box-nosearch"
                  : "customer-search-box"
              }
            >
              <Search
                placeholder="Search for your customers"
                results={customerSearchOptions.map((item) => ({
                  title: item.value,
                  key: item.key,
                }))}
                onSearchChange={handleCustomerSearchChange}
                onResultSelect={handleCustomerResultSelect}
                onKeyPress={onHandleKeyPress}
                open={openSearchList}
                onBlur={() =>
                  setTimeout(() => {
                    setOpenSearchList(false);
                  }, 100)
                }
                value={customerSearchKeyword}
              />
              {customerSearchTypeFullName && (
                <div className="customer-search-type">
                  {customerSearchTypeFullName}
                </div>
              )}
            </div>
            <hr />
            <div
              className="transfer-status"
              style={{ paddingRight: "0px !important" }}
            >
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column
                    width={16}
                    style={{ paddingTop: 0, paddingBottom: 10 }}
                  >
                    <div>
                      <Button
                        className="active-status"
                        size="small"
                        style={{
                          width: "100%",
                          padding: 11,
                        }}
                        onClick={() => {
                          setIsAddCompany(false);
                          setContactSaved(false);
                          setOnCustomerEdit(false);
                          setAddContactOpen(true);
                        }}
                      >
                        <Icon
                          name="add"
                          style={{ color: "#fff", marginLeft: -15 }}
                        />
                        Add New Contact
                      </Button>
                    </div>
                    <div>
                      <Button
                        className="default-status"
                        size="small"
                        style={{
                          width: "100%",
                          padding: 11,
                        }}
                        onClick={() => {
                          setOnCustomerEdit(false);
                          setCsvUploadOpen(true);
                        }}
                      >
                        Bulk Upload Contacts
                      </Button>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            <div className="transfer-list">
              <div style={{ position: "absolute", top: 300, left: 80 }}>
                {searchLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  >
                    Loading Customers...
                  </Loader>
                ) : null}
              </div>
              {customerList.length === 0 && (
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: 100,
                    paddingRight: 20,
                    color: "#AFABAB",
                    fontSize: 19,
                  }}
                >
                  <span>No Customers Found</span>
                </div>
              )}
              <div
                {...events}
                ref={ref}
                className="inventory-list-scroll"
                disabled={searchLoading}
                style={{ opacity: searchLoading ? 0.2 : 1, paddingTop: 5 }}
              >
                {jobListGrouped}
              </div>
            </div>
          </Grid.Column>
          {!formData?.id ? (
            <Grid.Column width={12} style={{ paddingRight: 0 }}></Grid.Column>
          ) : (
            <Grid.Column width={12} style={{ paddingRight: 0 }}>
              <Grid>
                <Grid.Column width={8}>
                  <div style={{ minHeight: 43.11, paddingTop: 5 }}>
                    {!onCustomerEdit ? (
                      <h2 style={{ fontWeight: "bolder" }}>
                        {formData?.companyName}
                      </h2>
                    ) : (
                      <Input
                        className="customer-input"
                        size="tiny"
                        style={{ padding: 0 }}
                        value={formData?.companyName}
                        onChange={(e, data) =>
                          handleChange("companyName", data.value)
                        }
                      />
                    )}
                  </div>
                </Grid.Column>
                <Grid.Column width={8}>
                  <div className="job-actions" style={{ textAlign: "right" }}>
                    {!onCustomerEdit ? (
                      <Button
                        className="default-status"
                        style={{
                          paddingLeft: 15,
                          paddingRight: 15,
                          minWidth: 130,
                        }}
                        size="small"
                        onClick={() => setOnCustomerEdit(true)}
                      >
                        Edit
                      </Button>
                    ) : (
                      <>
                        <Button
                          className="active-status"
                          style={{
                            paddingLeft: 15,
                            paddingRight: 15,
                            minWidth: 130,
                          }}
                          size="small"
                          onClick={() => onSubmitUpdateCustomer()}
                        >
                          Confirm
                        </Button>
                        <Button
                          className="default-status"
                          style={{
                            paddingLeft: 15,
                            paddingRight: 15,
                            minWidth: 130,
                          }}
                          size="small"
                          onClick={() => {
                            getCustomerData();
                            setOnCustomerEdit(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                  </div>
                </Grid.Column>
              </Grid>
              <hr />
              <div
                className="customer-form-content"
                style={{ paddingRight: "0px !important" }}
              >
                <Form size="tiny">
                  <Grid>
                    <Grid.Row style={{ paddingBottom: 0 }}>
                      <Grid.Column
                        width={1}
                        style={{
                          paddingTop: 17,
                          paddingBottom: "1rem",
                          paddingRight: 0,
                        }}
                      >
                        <Image
                          width={18}
                          src={rightArrow}
                          wrapped
                          style={{ marginTop: -8.5 }}
                        />
                      </Grid.Column>
                      <Grid.Column
                        width={15}
                        style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
                      >
                        <Header
                          as="h5"
                          style={{
                            marginLeft: -15,
                            fontSize: 18,
                            marginLeft: -35,
                          }}
                        >
                          Customer Details
                        </Header>
                      </Grid.Column>
                      <Grid.Column
                        width={16}
                        style={{
                          minHeight: 220,
                          padding: 0,
                          paddingTop: 15,
                          pointerEvents: onCustomerEdit ? "" : "none",
                          opacity: onCustomerEdit ? 1 : 0.75,
                        }}
                      >
                        <Grid
                          style={{
                            marginLeft: 0,
                            marginRight: 20,
                          }}
                        >
                          <Grid.Row style={{ padding: 5, paddingTop: 10 }}>
                            <Grid.Column width={8}>
                              <span
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: 15,
                                }}
                              >
                                Labour Fee
                              </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <span
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: 15,
                                }}
                              >
                                Travel Fee
                              </span>
                            </Grid.Column>
                            <Grid.Column
                              width={16}
                              style={{ paddingRight: 0 }}
                            ></Grid.Column>
                          </Grid.Row>
                          <Grid.Row style={{ padding: 5 }}>
                            <Grid.Column width={8}>
                              <Checkbox
                                radio
                                label="Use Global Value"
                                checked={formData?.useGlobalLabour}
                                value={formData?.useGlobalLabour}
                                onClick={() =>
                                  handleChange("useGlobalLabour", true)
                                }
                              />
                              <Checkbox
                                radio
                                label="Set ($/hr)"
                                style={{ paddingLeft: "1.5rem" }}
                                checked={!formData?.useGlobalLabour}
                                onClick={() =>
                                  handleChange("useGlobalLabour", false)
                                }
                              />
                              <Input
                                disabled={formData?.useGlobalLabour}
                                type="number"
                                style={{ paddingLeft: 10, width: "30%" }}
                                value={formData?.labourFee}
                                onChange={(e, data) =>
                                  handleChange("labourFee", data.value)
                                }
                              />
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Checkbox
                                radio
                                label="Use Global Value"
                                checked={formData?.useGlobalTravel}
                                value={formData?.useGlobalTravel}
                                onClick={() =>
                                  handleChange("useGlobalTravel", true)
                                }
                              />
                              <Checkbox
                                radio
                                label="Set ($/hr)"
                                style={{ paddingLeft: "1.5rem" }}
                                checked={!formData?.useGlobalTravel}
                                onClick={() =>
                                  handleChange("useGlobalTravel", false)
                                }
                              />
                              <Input
                                disabled={formData?.useGlobalTravel}
                                type="number"
                                style={{ paddingLeft: 10, width: "30%" }}
                                value={formData?.travelFee}
                                onChange={(e, data) =>
                                  handleChange("travelFee", data.value)
                                }
                              />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row style={{ padding: 5, paddingTop: 40 }}>
                            <Grid.Column width={8}>
                              <span
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: 15,
                                }}
                              >
                                Customer Specific Mark-up
                              </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <span
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: 15,
                                }}
                              >
                                Customer Discount (%)
                              </span>
                            </Grid.Column>
                            <Grid.Column
                              width={16}
                              style={{ paddingRight: 0 }}
                            ></Grid.Column>
                          </Grid.Row>
                          <Grid.Row style={{ padding: 5 }}>
                            <Grid.Column width={8}>
                              <Checkbox
                                radio
                                label="Use Global Value"
                                checked={formData?.useGlobalMarkup}
                                value={formData?.useGlobalMarkup}
                                onClick={() =>
                                  handleChange("useGlobalMarkup", true)
                                }
                              />
                              <Checkbox
                                radio
                                label="Set %"
                                style={{ paddingLeft: "1.5rem", width: 103 }}
                                checked={!formData?.useGlobalMarkup}
                                onClick={() =>
                                  handleChange("useGlobalMarkup", false)
                                }
                              />
                              <Input
                                disabled={formData?.useGlobalMarkup}
                                type="number"
                                style={{ paddingLeft: 20, width: "32.3%" }}
                                value={formData?.markupPercent}
                                onChange={(e, data) =>
                                  handleChange("markupPercent", data.value)
                                }
                              />
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Input
                                type="number"
                                style={{ width: "30%" }}
                                value={formData?.discountPercent}
                                onChange={(e, data) =>
                                  handleChange("discountPercent", data.value)
                                }
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                      <Grid.Column
                        width={1}
                        style={{
                          paddingTop: 17,
                          paddingBottom: "1rem",
                          paddingRight: 0,
                        }}
                      >
                        <Image
                          width={18}
                          src={rightArrow}
                          wrapped
                          style={{ marginTop: -8.5 }}
                        />
                      </Grid.Column>
                      <Grid.Column
                        width={15}
                        style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
                      >
                        <Header
                          as="h5"
                          style={{
                            marginLeft: -15,
                            fontSize: 18,
                            marginLeft: -35,
                          }}
                        >
                          Contacts
                        </Header>
                      </Grid.Column>
                      <Grid.Column
                        width={16}
                        style={{
                          padding: 0,
                          paddingTop: 15,
                        }}
                      >
                        <Grid
                          style={{
                            marginLeft: 0,
                            marginRight: 20,
                          }}
                        >
                          <Grid.Row style={{ padding: 5 }}>
                            <Grid.Column width={5}>
                              <span
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: 14,
                                }}
                              >
                                Contact Name
                              </span>
                            </Grid.Column>
                            <Grid.Column width={5}>
                              <span
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: 14,
                                }}
                              >
                                Phone Number
                              </span>
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <span
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: 14,
                                }}
                              >
                                Email Address
                              </span>
                            </Grid.Column>
                            <Grid.Column width={16} style={{ paddingRight: 0 }}>
                              <hr />
                            </Grid.Column>
                          </Grid.Row>
                          {formData?.customerContacts?.map((contact, index) => {
                            return (
                              <Grid.Row
                                columns={4}
                                style={{ padding: 5, fontSize: 15 }}
                              >
                                <Grid.Column width={5}>
                                  <span>{contact?.contactName}</span>
                                </Grid.Column>
                                <Grid.Column width={5}>
                                  <span>{contact?.contactNumber}</span>
                                </Grid.Column>
                                <Grid.Column width={5}>
                                  <span>{contact?.email}</span>
                                </Grid.Column>
                                <Grid.Column width={1}>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: 1,
                                      right: 5,
                                    }}
                                  >
                                    <Icon
                                      style={{ marginRight: 8 }}
                                      link
                                      name="pencil"
                                      onClick={() => {
                                        setContactFormData(contact);
                                        setTimeout(() => {
                                          setAddContactOpen(true);
                                        }, 300);
                                      }}
                                    />
                                    <Icon
                                      link
                                      name="trash"
                                      style={{ color: "red" }}
                                      onClick={() => {
                                        setSelectedContact(contact);
                                        setOpenRemoveContact(true);
                                      }}
                                    />
                                  </div>
                                </Grid.Column>
                              </Grid.Row>
                            );
                          })}
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </div>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    userInfo: state.auth.user,
  };
};

const reducer = { ...cartReducer, ...searchReducer };

const mapDispatchToProps = (dispatch) => bindActionCreators(reducer, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StockTransfer);
